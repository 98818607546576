import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'

export default function SERVICE_NOTIFICATION_TEMPLATE_PRU01({ data }) {

    const service = data.service_inspector.service
    const inquiry = service.proposal.inquiry
    const branch = inquiry.branch_spec.branch

    return (
        <Container>
            <DocumentHeader
				title="NOTIFICACIÓN DE INSPECCIÓN"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
			/>
            <SectionTitle>NOTIFICACIÓN DE INSPECTOR</SectionTitle>
            <table className='table all_borders mb-0'>
                <tbody>
                    <tr>
                        <td width="170px" className='bg-light bold'>Inspector Asignado:</td>
                        <td colSpan={3}>{data.service_inspector.user.name}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Correo Electrónico:</td>
                        <td>{data.service_inspector.user.email}</td>
                        <td width="150px" className='bg-light bold'>Celular:</td>
                        <td>{data.service_inspector.user.mobile}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Fecha Envío notificación:</td>
                        <td>{moment(data.notified_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                        <td className='bg-light bold'>Código Proceso:</td>
                        <td>{service.proposal.sku}</td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle>1. INFORMACIÓN DE LA INSTALACIÓN</SectionTitle>
            <table className='table all_borders mb-0'>
                <tbody>
                    <tr>
                        <td width="150px" className='bg-light bold'>Razón Social:</td>
                        <td>{branch.company.name}</td>
                        <td width="150px" className='bg-light bold'>NIT</td>
                        <td>{branch.company.complete_document}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Representante Legal:</td>
                        <td colSpan={3}>{branch.company.legal_owner_name}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Nombre de Instalación:</td>
                        <td>{branch.name}</td>
                        <td className='bg-light bold'>Tipo de Instalación:</td>
                        <td>{branch.instalation_type_name}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Correo electrónico</td>
                        <td colSpan={3}>{branch.email}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Dirección</td>
                        <td>{branch.address_line}</td>
                        <td className='bg-light bold'>Ciudad</td>
                        <td>{branch.address_state}, {branch.address_city}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Teléfono</td>
                        <td>{branch.phone}</td>
                        <td className='bg-light bold'>Celular</td>
                        <td>{branch.mobile}</td>
                    </tr>
                </tbody>
            </table>
            <SectionTitle>2. INFORMACIÓN DE LA INSPECCIÓN</SectionTitle>
            <table className='table all_border mb-0'>
                <tbody>
                    <tr>
                        <td className='bg-light bold'>Número de tanques*</td>
                        <td>{data.service_inspector.test_details.total_tanks}</td>
                        <td className='bg-light bold'>Altura aproximada del desfogue*</td>
                        <td>{data.service_inspector.test_details.aprox_altitude}</td>
                        <td className='bg-light bold'>Válvula para independizar desfogue*</td>
                        <td>{data.service_inspector.test_details.valve_present}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Criterio:</td>
                        <td colSpan={5} className='small'>{service.criteria}</td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Inspección</td>
                        <td colSpan={5} className='p-1'>
                            <table className='table all_border mb-0'>
                                <tbody>
                                    <tr className='text-center'>
                                        <td className='bg-light bold'>Lugar</td>
                                        <td className='bg-light bold'>Horas Aprox.</td>
                                        <td className='bg-light bold'>Fecha</td>
                                    </tr>
                                    <tr className='text-center'>
                                        <td>{inquiry.branch_spec.branch.address_line}</td>
                                        <td>{service.hours_estimate}</td>
                                        <td>{moment(service.start_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className='bg-light bold'>Observadores**:</td>
                        <td colSpan={5}>{service.observers}</td>
                    </tr>
                </tbody>
            </table>
            <div className='p-3 pb-0'>
                <small>*Información diligenciada en la Solicitud de servicio y que corresponde a la propuesta comercial del proceso. 
                ** Acompañaran las actividades de inspección, pero no es parte de estas, y no influencian ni interfieren con la realización de la inspección ni los hallazgos ni conclusiones de esta.</small>
            </div>
            <div className='p-3 pb-0'>
                <p className='pl-5 mt-5 small' style={{ whiteSpace: 'pre-line' }}>
                <span style={{ whiteSpace: 'pre-line' }}>{data.observations}</span>
                {`\n- Los cambios a la notificación deben solicitarse por escrito a la Coordinación de programación, siempre y cuando este en cumplimiento al Reglamento de inspección DN5 y procedimiento de inspección PN2.\n- Si pasados tres (3) días de recibida esta notificación no se ha comunicado ninguna solicitud de modificación se considera que es aceptada y se desarrollará según lo establecido. Recuerde que, bajo solicitud, tenemos disponibles procedimientos de DEWAR, para las actividades de inspección, así como de quejas y apelaciones, entre otros.`}
                </p>
                <br />
                Atentamente,<br />
                <strong>Coordinadora de programación</strong><br />
                <strong>DEWAR –OIN</strong>
            </div>
        </Container>
    )
}



const Container = styled.div`
    font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	min-height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 5px 3px;
	}
    background-color: #fff;
    span.variable {
        color: #e78035;
        font-weight: 600;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
`

const SectionTitle = styled.div`
	font-weight: bold;
	padding: 2px 6px;
	margin: 10px 0px 5px;
	color: black;
	text-align: ${props => props.center ? 'center' : 'left'};
`