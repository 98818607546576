import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import { currency } from '../../../../helpers'

export default function RETRY_NOTICE_TEMPLATE_INS01({ data }) {
	let hasPrices = data.service_value && data.service_value > 0

	const service = data.service_review.service_plan.service_inspector.service
	const proposal = service.proposal
	const company = proposal.inquiry.branch_spec.branch.company

	return (
		<Document>
			<DocumentHeader 
				title="COMUNICADO INSPECCIÓN DE CIERRE"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
			/>
			<p className='text-right mb-10'>Bogotá D.C., {moment(data.sent_at).format('DD MMMM YYYY')}</p>
			<p className='mb-5'>Señores:</p>
			<p className='mb-5'><strong>{company.legal_owner_name}</strong></p>
			<p className='mb-5'><strong>INSTALACIÓN {proposal.inquiry.branch_spec.branch.name}</strong></p>
			<p className='mb-5'>{proposal.inquiry.branch_spec.branch.address_line}</p>
			<p className='mb-20'>{proposal.inquiry.branch_spec.branch.address_city}</p>
			<p className='mb-20 text-right'><strong>Ref: Comunicado Inspección de Cierre proceso #{service.id}</strong></p>
			<p className='mb-20'>Apreciados Señores:</p>
			{ !data.is_warning ? (
				<React.Fragment>
					<p>Teniendo en cuenta su solicitud frente a la programación de la inspección de cierre, la misma se adelantará de acuerdo con la propuesta comercial {proposal.sku} previamente aceptada, para culminar el proceso de inspección se debe realizar la inspección de cierre para evidenciar la solución eficaz de las no conformidades identificadas en una inspección inicial; la cual se adelantará bajo los siguientes términos:</p>
				</React.Fragment>
			) : (
				<React.Fragment>
					<p>Teniendo en cuenta los términos y tiempos del proceso de inspección de referencia, les comunicamos que es necesario programar antes del {moment(data.service_review.service_plan.inspected_at).add(90,'days').format('DD MMMM YYYY')} fecha establecida para realizar la inspección de cierre, en cumplimiento del Reglamento de inspección y procedimiento de inspección de DEWAR SAS.</p>
					<p>De acuerdo con la propuesta comercial {proposal.sku} previamente aceptada, para culminar el proceso de inspección se debe realizar la inspección de cierre para evidenciar la solución eficaz de las no conformidades identificadas en una inspección inicial; la cual se adelantará bajo los siguientes términos:</p>
				</React.Fragment>
			)}
			<ul>
				<li>La inspección de cierre se debe realizar máximo a los 90 días calendario posteriores a la inspección inicial.</li>
				<li>La inspección de cierre solo se puede realizar una vez para cada proceso de inspección, en el momento que el CLIENTE cuente con las evidencias suficientes y totales, y las presentes bajo las especificaciones solicitadas por el inspector, en cumplimiento del procedimiento de inspección.</li>
				<li>La inspección de cierre solo se incluirá la evaluación de evidencias de solución a las no conformidades identificadas en la inspección inicial.</li>
				<li>Una vez se cuente con los resultados de la inspección de cierre se presentará el informe al Comité Técnico quien tomará la decisión frente al proceso de inspección de referencia.</li>
				<li>En caso de que la instalación no acepte ni realice la inspección de cierre en los tiempos establecidos por el Reglamento de inspección, el proceso de inspección se dará por cerrado, y el inspector del proceso presentará el informe para que el Comité Técnico tome la decisión correspondiente.</li>
			</ul>
			{ hasPrices && (
				<React.Fragment>
					<p>En cumplimiento del Reglamento de inspección y teniendo en cuenta las especificaciones de la inspección de cierre, se establece el siguiente costo adicional, que, para continuar con el proceso, deberá se cancelado por la instalación antes de la programación de la inspección de cierre:</p>
					<div style={{ maxWidth: '500px', margin: 'auto' }}>
						<table className='table full-width border'>
							<tbody>
								<tr className='bg-primary-light bold text-center'>
									<td colSpan={2}>COSTO ADICIONAL PROCESO DE INSPECCIÓN DE CIERRE</td>
								</tr>
								<tr className='bg-light bold text-center'>
									<td>ACTIVIDAD</td>
									<td>VALOR</td>
								</tr>
								<tr>
									<td>Inspección de cierre</td>
									<td>{currency(data.service_value)}</td>
								</tr>
								<tr>
									<td>Gastos de viaje</td>
									<td>{currency(data.trip_expenses_value)}</td>
								</tr>
								<tr>
									<td colSpan={2}></td>
								</tr>
								<tr>
									<td><strong>Total del servicio antes de IVA</strong></td>
									<td><strong>${currency(parseInt(data.service_value) + parseInt(data.trip_expenses_value))}</strong></td>
								</tr>
							</tbody>
						</table>
					</div>
					<ul>
						<li>El precio de gastos de viaje puede variar, en el caso que por conveniencia del cliente asume directamente el costo de tiquetes aéreos.</li>
						<li>En caso de que el cliente asuma el costo de tiquetes se realizará la variación en el total del servicio en la correspondiente cuenta de cobro/factura.</li>
						<li>Los costos del tiquete están sujetos a variaciones de acuerdo con la programación que se realice.</li>
						<li>El precio total por cancelar es el Total del servicio de inspección más IVA</li>
					</ul>
				</React.Fragment>
			)}
			<p className='mt-30'>Así las cosas, esperamos que tengamos su respuesta favorable para continuar con el proceso de inspección, agradecemos, solicitarnos la información que consideren necesaria para  llevar a buen término el proceso de la referencia  a su más pronta conveniencia.</p>
			<p className='mb-40'>Cordialmente,</p>
			<p className='mb-5'>Coordinadora de Programación.</p>
			<p className='mb-5'>Dewar S.A.S.</p>
			<p className='text-center mt-30'>
				Carrera 61 # 96 A 23 Oficina 201 Barrio Andes, Bogotá – Colombia
			</p>
			<p className='text-center mt-10'>
				Celular 3163702077 programacion@dewar.com.co – www.dewar.com.co
			</p>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px 40px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 15px;
	}
`